import './App.css';
import twitterLogo from './assets/twitter-logo.svg';
import profilePic from './assets/profile.jpeg'
import { useEffect } from 'react';


function App() {

  const TWITTER_HANDLE = 'OmniscientHrzn';
  const TWITTER_LINK = `https://twitter.com/${TWITTER_HANDLE}`;

  const GITHUB_HANDLE= 'omniscientHorizon';
  const GITHUB_LINK= `https://github.com/${GITHUB_HANDLE}`;

  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault();

        const targetId = this.getAttribute('href');
        const targetElement = document.querySelector(targetId);

        if (targetElement) {
          targetElement.scrollIntoView({
            behavior: 'smooth'
          });
        }
      });
    });
  }, []);

  return (
    <div className="App">
			<div className="container">

        <div className='options'>
            <a href="#intro">Home</a>
            <a href='#journey'>About</a>
            <a href='#projects'>Projects</a>
        </div>
       
        <div className='intro' id="intro"> 

            <h1 style={{ display: 'none' }}>Kshitij Agarwal's Journey</h1>

            <p>
              Hi!👋 <br /> I am Kshitij Agarwal aka OmniscientHorizon. <br /> College Freshman | Enthusiastic Developer | Author of <a href='https://amzn.eu/d/8Ja05X1' target='_blank'>Blockchain for Business</a> <br />
            </p>
            <img className ='profilePic left' src={profilePic} />
          

          
            <p className="">Twitter <a
              href={TWITTER_LINK}
              target="_blank"
              rel="noreferrer"
            >{`@${TWITTER_HANDLE}`}</a> | 
            Github: <a
              href={GITHUB_LINK}
              target="_blank"
              rel="noreferrer"
            >{`@${GITHUB_HANDLE}`}</a> | <a href='mailto:omniscienthorizon@gmail.com'>say hi👋</a> | <a href='https://www.buymeacoffee.com/kshitij11' target='_blank'>buy me a coffee ☕️</a>
            </p>
            
            <hr />
            <br />

        </div>

        <main>

          <section id="journey">
              <h2 style={{ display: 'none' }}>Kshitij Agarwal's Journey</h2>
              <b>My Journey</b> <br />
              {/* <h2>My Journey</h2> */}
              <br />

              Online schooling and comfortable hours had given me the impetus to seek something outside the ordinary. In November 2021 I started to read about cryptocurrency and blockchain, and on realising the potential decentralisation had to transform every field I was absolutely hooked to it. I spent 8-10 hours everyday reading articles, watching videos and reaching out to people who worked in this space to learn more. During my search, I came across <a target='_blank' href='https://buildspace.so/'>Buildspace</a>  and joined this small community which was teaching people how to program and develop in the blockchain world. For the next few months, I learned on my own from Buildspace. <br /><br />

              During this time, I was searching for instructor-led courses and bootcamps. Most of them rejected me even after I passed their screening tests because I was a minor under 18 and not someone who had done 4 years of college. But this did not stop me, eventually I came across <a target='_blank' href='https://www.chainshot.com/'>Chainshot</a> (now AlchemyUniversity)- a company which offered an intensive instructor-led Ethererum bootcamp. After many emails and tests with them, I got into their Ethereum bootcamp which, like the others, was usually just offered to post-graduates. <br /><br />

              For the next three months, from January to March, I used to spend hours everyday learning from their instructors and building projects to solve real-life issues. During this time my final exams of class 11 were also going on, but I would still work on Ethereum and Blockchain everyday from 10pm to 3 am, and then wake up at 6am and go to school to give a test. I graduated from this bootcamp towards the end of March. For my graduation project, I worked with two people, one from Africa and the other from Europe. We built - consistently- a web3 solution of a web app which would incentivise and help users build habits, based on the primary algorithms of the human brains understanding of punishment and reward. It was a social platform which gave users NFTs and Tokens to build good habits <a target='_blank'href='https://www.chainshot.com/article/consistently'>Consistently</a> <br /> <br />

              Towards the later half of March, I took part in my first international hackathon- LFGrow by Ethereum Global. During this time, I worked on a project - <a taregt='_blank'href='https://showcase.ethglobal.com/lfgrow/b-trax-mi7o7'>b.trax</a> with four others from across the globe. My team went on to win the Audio category and a prize of $1250. <br /><br />

              All this time, I was extensively a part of the web3 community of buildspace. I was an Event-Helper/ Assistant at Buildspace demo day, during which I helped organize an online web3 project exhibition to showcase products to investors and developers and guided over 500 attendees across the online map and managed booths on Gather (online platform). <br /><br />

              Owing to me showing up and helping out people on their web3 journey in buildspace a few people called me - <b>‘the solidity wizard’ </b>and I am ranked 48 in a 150K+ community of developers from across the world in Buildspace. <br /><br />

              I wanted to do something to help out other developers my age and after several efforts, I got the position of the head of Gen-Z at buildspace. We Created a community for gen-z developers to learn about blockchain development and provided a space for 2000+ (still growing) teenage developers to discuss ideas, build projects and get funds. We got 10,000$ in the treasury from Buildspace to support the products built by the community. <br /><br />

              In October 2022, I applied to PadawanDAO- a group which sponsors and provides a scholarship (all costs for flights, accommodation, food, transport etc.) for teenagers who are developers in the Web3 and Blockchain world. After strenuous rounds of selection, I was one of the handful of teenagers chosen from across the globe to attend the biggest Developer conference in Bogota, Colombia. But owing to VISA issues, I was unable to get there. I further got selected to take part in ETH SAN FRANCISCO- the biggest Ethereum Developer Conference this year. And from November 4-6th I was in San Francisco, California, USA taking part in the biggest conference, hackathon. From India, only two people were selected to get this sponsorship cum scholarship to San Francisco, and I am one of them. In this Hackathon my team went on to win the third prize from Unlock Protocol and won a prize money of 500$. We built a minimum viable product of a simplified use case of what we wanted, our product targets any companies that buys software licenses. We built <a target='_blank' href='https://ethglobal.com/showcase/alexandria-3db0q'>Alexandria</a> During the hackathon, several founders and VCs showed interest in our product too. <br /><br />

              Overall this has been an amazing journey and I keep building, taking part in hackathons, and bounties and somehow winning. Each day I take a step closer to my vision of doing something relevant and useful with this tech. I long and dream to do something for the millions of unemployed engineers in our country in the future. <br /> <hr />

              <br />

          </section>

          <section id="projects">

            <h2 style={{ display: 'none' }}>Kshitij Agarwal's Projects</h2>
            <b>My Work:</b>

            <br />

            <br />

            <a
              href="https://ethglobal.com/showcase/b-trax-mi7o7"
              target="_blank"
              rel="noreferrer"
            ><b>BTRAX</b></a>

            <p>
            btrax is the instrumental marketplace and community of the future, a place where musicians can exchange sounds and create their own personalized beat stores.

  btrax was inspired by the needs of audio professionals to assist them in keeping their portfolios organized and transparent for them and their customers.
            </p>

            <br />

            <a
              href="https://ethglobal.com/showcase/alexandria-3db0q"
              target="_blank"
              rel="noreferrer"
            ><b>ALEXANDRIA</b></a>

            <p>
            Alexandria is an onchain library that allows you to borrow & read books, claim NFTs. If you are an author then say hey to royalties. If you don't return the book in time we'll take it ourselves. So forget paying unnecessary fines & enjoy yourselves at the library of the future.

  MVP-- future plans are to build a web3 substack using Alexandria as the foundation

  Apprpached by Alliance Accelerators, made it to L2 of fund interviws
            </p>

            <br />

            <a
              href="https://interviewninja.xyz"
              target="_blank"
              rel="noreferrer"
            ><b>INTERVIEW NINJA</b></a>

            <p>
            A college interview preparation tool now at your fingertips! Our website offers a comprehensive collection of flashcards featuring the questions frequently asked by college interviewers to determine your admission eligibility. With a built-in timer and valuable tips, InterviewNinja.xyz ensures you're well-equipped and confident for your college interviews. Elevate your chances of securing a spot at your dream university by mastering the art of interviews with InterviewNinja.
            </p>

            <br />

	<a
    href="https://github.com/omniscientHorizon/GreekLife-SentimentAnalysis/blob/main/Copy_of_KA_Copy_of_206_Final_Project_KSHITIJ.ipynb"
    target="_blank"
  ><b>GREEK LIFE SENTIMENT ANALYSIS</b></a>
  <p>
    An analysis of over 120 years of Union College's Concordiensis archives using Natural Language Processing (NLP). This project uncovers trends in sentiment and language related to Greek Life, revealing its evolving role on campus. Tools used include Python, Google Colab, and VADER Sentiment Analysis.
  </p>
  <br />
            
            <hr />

          </section>

				</main>

			</div>
      
      <footer>
          Kshitij Agarwal | coachkshitijagarwal@gmail.com 
      </footer>

		</div>
  );
}

export default App;
